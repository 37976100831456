import Vue from 'vue'
import Vuex from 'vuex'
import {getPersonInfo} from "@/views/desktop/api";
import router from '../router';
import {Message} from "element-ui";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    person: ''
  },
  getters: {
    getPersonInformation: state => state.person
  },
  mutations: {
    setPersonInformation(state, value) {
      state.person = value;
    }
  },
  actions: {
    async fetchPersonInformation({ commit }) {
      const response = await getPersonInfo();
      const {data, result, message} = response.data;
      commit('setPersonInformation', data);
      if(!result) {
        Message.error(message);
        await router.push({
          name: 'LoginView'
        });
      }
    }
  },
})
