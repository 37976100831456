import axios from 'axios';
import {Message} from 'element-ui';
import router from '../router';

const http = axios.create({
    baseURL: '/api', // 你的 Spring Boot 服务器地址
    timeout: 100000, // 请求超时时间
    withCredentials: true
});

http.interceptors.request.use(
    config => {
        // 从本地存储中获取 Token，假设 Token 存储在 localStorage 中
        const token = localStorage.getItem('Token');

        if (token) {
            // 在请求头中添加 Authorization 字段
            config.headers['Token'] = token;
        }

        return config;
    },
    error => {
        // 请求错误处理
        return Promise.reject(error);
    }
);


// 响应拦截器
http.interceptors.response.use(
    response => {
        if(response.config.url === '/user/login/password-login' ||
            response.config.url === '/user/login/email-login' ||
            response.config.url === '/user/login/face-login'){
            if(response.headers.token) {
                console.log('登录凭证信息: ' + response.headers.token)
                localStorage.setItem("Token", response.headers.token);
            }
        }
        return response;
    },
    error => {
        // 响应错误处理，检测是否为 session 过期或未授权状态
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            console.log(error.response)
            // 可以根据实际项目需求做相应的处理，比如：
            Message.error(error.response.data.message);
            router.push({
                name: 'LoginView'
            });
        } else if (error.request) {
            if (error.message.includes('timeout')) {
                Message.error('请求超时， 请检查网络或稍后重试');
            } else {
                Message.error('请求未响应，请检查网络或稍后重试');
            }
        } else {
            Message.error(error.message)
            router.push({
                name: 'LoginView'
            });
        }
    }
);


export default http;