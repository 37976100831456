import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'webuploader/dist/webuploader.css';
import * as faceapi from 'face-api.js';
Vue.use(ElementUI);

Vue.config.productionTip = false

function generateRandomString(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
}

const init = async () => {
  Promise.all([
    faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
    faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
    faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
    faceapi.nets.faceExpressionNet.load('/models'),
    faceapi.nets.faceLandmark68TinyNet.loadFromUri('/models'),
    faceapi.nets.ssdMobilenetv1.loadFromUri("/models"),
    faceapi.loadFaceLandmarkModel("/models"),
  ]).then(() => {
    console.log('人脸识别初始化成功')
  }).catch((error) => {
    console.log('失败', error)
  });
}


function initEquipment() {
  let equipment = localStorage.getItem('equipment');
  if (equipment) {
    window.equipment = equipment
    console.log('本设备设备号：' + equipment)
  }else {
    const timestamp = Date.now().toString();
    const randomString = generateRandomString(10);
    const randomNumber = timestamp + randomString;
    console.log('本设备设备号：' + randomNumber)
    localStorage.setItem('equipment', randomNumber);
    window.equipment = randomNumber
  }
  const date = new Date();
  date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000)); // 7天
  const expires = "expires=" + date.toUTCString();
  document.cookie = "equipment=" + window.equipment + ";" + expires + "; path=/";
}

initEquipment()
await init()


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
