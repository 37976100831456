import http from '@/services/api'

export function getPersonInfo() {
    return http({
        url: '/user/login/verify-identity',
        method: 'GET'
    })
}

export function sendEmailCode(data) {
    return http({
        url: '/user/login/send-email-code',
        method: 'GET',
        params: data
    })
}

export function updateEmail(data) {
    return http({
        url: '/user/login/update-email',
        method: 'POST',
        data: data
    })
}

export function updatePerson(data) {
    return http({
        url: '/user/login/update-person',
        method: 'POST',
        data: data
    })
}